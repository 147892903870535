/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError, Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { ConfigService } from './config.service';

export interface GetAllBackupListWithVersionResponse {
  success: boolean;
  data: {
    backupVersions: { id: number, version: number }[];
  }
}

export interface GetAllBackupListResponse {
  success: boolean; data: number[]
}

@Injectable({
  providedIn: 'root'
})
export class BackupApiService extends BaseHttpService {
  constructor(
    private superHttp: HttpClient,
    private superConfig: ConfigService,
  ) {
    super(superHttp, superConfig);
  }

  /**
   * отправка бекапа на сервер
   * @returns {any}
   * @param orderId
   * @param zipBlob
   * @param backupVersion
   * @param overwrite
   * @param idempotencyKey
   */
  sendBackup(orderId: string, zipBlob: Blob, backupVersion: number, overwrite: boolean, idempotencyKey: string) {
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Backup-Version': backupVersion.toString(),
    };
    return this.http.post<any>(
      `${this.baseRestURL}forwarding/backup/${orderId}/${idempotencyKey}`,
      zipBlob,
      {
        headers,
        params: { overwrite: overwrite.toString() }
      }
    );
  }

  /**
   * получение списка бекапов
   * @returns {any}
   */
  getAllBackupList() {
    return this.http.get<GetAllBackupListResponse>(`${this.baseRestURL}forwarding/backup/all`)
      .pipe(
        catchError(error =>
          // console.error('Ошибка при выполнении запроса:', error);
          throwError(() => new Error(error)))
      );
  }

  getAllBackupListWithVersion() {
    return this.http.get<GetAllBackupListWithVersionResponse>(`${this.baseRestURL}forwarding/backup/version/all`)
      .pipe(
        catchError(error =>
          // console.error('Ошибка при выполнении запроса:', error);
          throwError(() => new Error(error)))
      );
  }

  /**
   * получить содержимое бекапа по ИД
   * @param {any} id:number
   * @returns {any}
   */
  getBackupById(id: number): Observable<any> {
    return this.http.get(`${this.baseRestURL}forwarding/backup/${id}`, { responseType: 'blob', observe: 'response' })
      .pipe(
        catchError(error => {
          if (error.status === 204) {
            // console.log('Для этой заявки нет бэкапа');
          }
          // console.error('Ошибка при выполнении запроса:', error);
          return throwError(() => new Error(error));
        })
      );
  }
}
