import { Component, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { HeaderDto } from 'src/app/dto/header.dto';
import { Order } from 'src/app/dto/order.dto';
import { OrderChangesService } from 'src/app/services/order-changes.service';
import { CarEditorData } from '../../../../shared/car-editor/car-editor.component';

@Component({
  selector: 'app-new-car',
  templateUrl: './new-car.component.html',
  styleUrls: ['./new-car.component.scss']
})
export class NewCarComponent {
  @Output() editFormSubmitted?: RtsVehicle;

  requestNumber: string = '';
  requestDayId: string = '';
  currentActionRequest: Order;
  targetUrl = window.location.pathname.split('/')[1];

  headerDataValues: HeaderDto = {
    title: 'Новая машина'
  };

  constructor(
    private orderChangesService: OrderChangesService,
    public navCtrl: NavController
  ) {
    this.requestNumber =
      window.location.pathname.split('/')[
      window.location.pathname.split('/').findIndex(el => el === 'days') + 1
      ];

    this.requestDayId =
      window.location.pathname.split('/')[
      window.location.pathname.split('/').findIndex(el => el === 'day') + 1
      ];
  }

  addNewCar(formData: Partial<CarEditorData>) {
    this.orderChangesService
      .getOrderSubject(this.targetUrl)
      .subscribe(r => (this.currentActionRequest = r!));

    const vehicle: Partial<RtsVehicle> = {
      ...formData,
      id: new Date().valueOf(),
      hasTrailer: !!formData.trailerRegistrationNumber
    };

    this.orderChangesService.updateRequests(
      this.currentActionRequest.addVehicle(this.requestDayId, <RtsVehicle> vehicle),
      this.targetUrl
    );

    this.navCtrl.pop();
  }
}
