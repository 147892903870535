  import { Injectable } from '@angular/core';
import { ExcelGeneratorData } from 'src/app/dto/excel-generation-data.dto';
import { Order } from 'src/app/dto/order.dto';
import { XlsxTemplateFillerServiceService } from './xlsx-template-filler-service.service';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class ForwarderReportGeneratorService {
  private readonly trnTemplatePath: string =
    'assets/docs/forwarder-report.xlsx';

  private startIndex = 16;

  constructor(
    private xlsxTemplateFillerServiceService: XlsxTemplateFillerServiceService,
    private dateTimeService: DateTimeService
  ) { }

  async getForwarderReport(order: Order): Promise<void> {
    const data = await this.generateForwarderReportDataStructure(order);
    await this.xlsxTemplateFillerServiceService.generateAndOpenNewExcelFile(
      this.trnTemplatePath,
      data,
      `Отчет экспедитора-${order.id}`
    );
  }

  private async generateForwarderReportDataStructure(order: Order) {
    const generateForwarderReportData: ExcelGeneratorData = {
      cellDataArray: [],
      additionalOptions: {
        removeCellPoints: [
          {
            pageNumber: 1,
            startRow: 17,
            endRow: 999
          }
        ]
      }
    };
    let recordCounter = 0;
    let totalWeight = 0;
    order.registry.days.forEach(day => {
      if (day.completed) {
        day.vehicles.forEach((vehicle, carIndex) => {
          if (vehicle.loadingData) {
            recordCounter += 1;
            const recordIndex = this.startIndex + recordCounter;
            totalWeight += parseFloat((order.deliveredWeightInKilograms / 1000).toFixed(2));
            generateForwarderReportData!.additionalOptions!.removeCellPoints?.map(el => el.startRow = recordIndex + 1);
            generateForwarderReportData.cellDataArray.push({
              address: `A${recordIndex}`,
              value: `${recordCounter}`,
              name: 'number',
              pageNumber: 1
            });

            generateForwarderReportData.cellDataArray.push({
              address: `C${recordIndex}`,
              // eslint-disable-next-line max-len
              value: `Перевозка  ${parseFloat((vehicle.loadingData.netWeight / 1000).toFixed(2))}т на ${order?.distance}км, ${order?.shipmentDates}.Пункт отгрузки  ${order.loading.title} ${order.loading.address}. Пункт разгрузки: ${order.unloading.title} ${order.unloading.address} Перевозчик: ${vehicle.carrierName}, ${vehicle.registrationNumber} на основании ${this.getTtnNumber(order, carIndex)} от ${this.dateTimeService.formatDate(String(order.updatedDate))}, заявка №${order.id} `,
              name: 'operation-note',
              pageNumber: 1
            });

            generateForwarderReportData.cellDataArray.push({
              address: `Q${recordIndex}`,
              value: `${vehicle.carrierName} ИНН ${vehicle.carrierInn}`,
              name: 'forwarder-org',
              pageNumber: 1
            });

            generateForwarderReportData.cellDataArray.push({
              address: `AC${recordIndex}`,
              value: `Пункт отгрузки  ${order.loading.title} ${order.loading.address}.
              Пункт разгрузки: ${order.unloading.title} ${order.unloading.address}`,
              name: 'forwarding-path',
              pageNumber: 1
            });

            generateForwarderReportData.cellDataArray.push({
              address: `AL${recordIndex}`,
              value: ` ${parseFloat((order.deliveredWeightInKilograms / 1000).toFixed(2))}`,
              name: 'net-weight',
              pageNumber: 1
            });

            // generateForwarderReportData.cellDataArray.push({
            //   address: `AR${recordIndex}`,
            //   value: 'Договор перевозки №',
            //   name: '',
            //   pageNumber: 1
            // });
            //
            // generateForwarderReportData.cellDataArray.push({
            //   address: `AX${recordIndex}`,
            //   value: 'УПД №',
            //   name: '',
            //   pageNumber: 1
            // });
            //
            // generateForwarderReportData.cellDataArray.push({
            //   address: `BD${recordIndex}`,
            //   value: `${order.forwarderFee * parseFloat((order.deliveredWeightInKilograms / 1000).toFixed(2))}`,
            //   name: '',
            //   pageNumber: 1
            // });

            generateForwarderReportData.cellDataArray.push({
              address: `BP${recordIndex}`,
              value: `${order.forwarderFee * parseFloat((order.deliveredWeightInKilograms / 1000).toFixed(2))}`,
              name: '',
              pageNumber: 1
            });
          }
        });
      }
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'AL1000',
      value: `${totalWeight}`,
      name: 'total-weight',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'BP1000',
      value: `${totalWeight * order.forwarderFee}`,
      name: 'total-fee',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'AT4',
      value: `${order.id}-${Date.now().toString().slice(-5)}`,
      name: 'order-id',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'BB4',
      value: `${this.dateTimeService.getNowDateStringFormat()}`,
      name: 'created-date',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'AN7',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });
    generateForwarderReportData.cellDataArray.push({
      address: 'AF9',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'AX7',
      value: `${this.dateTimeService.getNowDateStringFormat()}`,
      name: '',
      pageNumber: 1
    });

    if (order.createdDate) {
      generateForwarderReportData.cellDataArray.push({
        address: 'AK9',
        value: `${this.dateTimeService.formatDate(String(order.createdDate))}`,
        name: '',
        pageNumber: 1
      });
    }

    if (order.freightName) {
      generateForwarderReportData.cellDataArray.push({
        address: 'J11',
        value: `${order.freightName}`,
        name: 'freight-name',
        pageNumber: 1
      });
    }

    order.generateForwarderReportActions(
      generateForwarderReportData.cellDataArray
    );

    return generateForwarderReportData;
  }

   private getTtnNumber(order:Order, carIndex:number) {
    const today = new Date();
    return `ТТН №${order.id}-${(today.getMonth() + 1).toString().padStart(2, '0')}
   ${(today.getDate()).toString().padStart(2, '0')}${(carIndex).toString().padStart(2, '0')}`;
  }
}
