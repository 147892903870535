import { Injectable } from '@angular/core';
import { SecureStorageObject } from '@awesome-cordova-plugins/secure-storage';
import { SecureStorage } from '@awesome-cordova-plugins/secure-storage/ngx';
import { Capacitor } from '@capacitor/core';

interface StorageProxy {
  set(key: string, value: string): Promise<void>;
  get(key: string): Promise<string | null>;
  remove(key: string): Promise<string | null>;
}

const SECURE_STORAGE_NAME: string = 'expeditor';

const FALLBACK_STORAGE: StorageProxy = {
  get(key: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(`${SECURE_STORAGE_NAME}/${key}`));
  },

  set(key: string, value: string): Promise<void> {
    localStorage.setItem(`${SECURE_STORAGE_NAME}/${key}`, value);
    return Promise.resolve();
  },

  remove(key: string): Promise<string | null> {
    const keyWithPrefix: string = `${SECURE_STORAGE_NAME}/${key}`;
    const currentValue = localStorage.getItem(keyWithPrefix);
    localStorage.removeItem(keyWithPrefix);
    return Promise.resolve(currentValue);
  }
};

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {
  private storageObject: StorageProxy;

  constructor(private readonly secureStorage: SecureStorage) {
  }

  public async storeData(key: string, value: string): Promise<void> {
    if (!this.storageObject) {
      this.storageObject = await this.init();
    }
    return this.storageObject.set(key, value);
  }

  public async fetchData(key: string): Promise<string | null> {
    if (!this.storageObject) {
      this.storageObject = await this.init();
    }
    return this.storageObject.get(key);
  }

  public async removeData(key: string): Promise<string | null> {
    if (!this.storageObject) {
      this.storageObject = await this.init();
    }
    return this.storageObject.remove(key);
  }

  private async init(): Promise<StorageProxy> {
    const secureStorageObject: SecureStorageObject = await this.secureStorage.create(SECURE_STORAGE_NAME);
    if (secureStorageObject) {
      return secureStorageObject;
    }
    if (Capacitor.isNativePlatform()) {
      throw new Error('Cordova SecureStorage не доступен!');
    }
    return FALLBACK_STORAGE;
  }
}
