import { Injectable } from '@angular/core';
import { UserDataKey } from './user-management.service';

export interface EncryptedData {
  iv: string;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private readonly ivLength: number = 12;

  public async encryptData(plainText: string, secretKey: CryptoKey): Promise<EncryptedData> {
    const iv: Uint8Array = crypto.getRandomValues(new Uint8Array(this.ivLength));
    const encodedText: Uint8Array = new TextEncoder().encode(plainText);
    const encryptedBuffer: ArrayBufferLike = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv,
        tagLength: 128
      },
      secretKey,
      encodedText
    );
    const encryptedArray: Uint8Array = new Uint8Array(encryptedBuffer);
    const encryptedData: string = btoa(String.fromCharCode(...encryptedArray));
    const encodedIv: string = btoa(String.fromCharCode(...iv));
    return { iv: encodedIv, data: encryptedData };
  }

  public async decryptData(encryptedData: EncryptedData, key: CryptoKey): Promise<string> {
    const ivArray: Uint8Array = this.base64ToUint8Array(encryptedData.iv);
    const decryptedData: ArrayBuffer = await this.decryptDataToBuffer(encryptedData.data, key, ivArray);
    return new TextDecoder().decode(decryptedData);
  }

  public async decryptDataKey(dataKey: UserDataKey, password: string): Promise<string> {
    const salt: Uint8Array = this.base64ToUint8Array(dataKey.salt);
    const passwordKey: CryptoKey = await this.generateKeyFromPassword(password, salt);
    const iv: Uint8Array = this.base64ToUint8Array(dataKey.iv);
    const dek: ArrayBuffer = await this.decryptDataToBuffer(dataKey.key, passwordKey, iv);
    return btoa(String.fromCharCode(...new Uint8Array(dek)));
  }

  public async loadKeyFromString(base64Key: string): Promise<CryptoKey> {
    return this.importKey(this.base64ToUint8Array(base64Key));
  }

  private async generateKeyFromPassword(password: string, salt: Uint8Array): Promise<CryptoKey> {
    const passwordData: Uint8Array = new TextEncoder().encode(password);
    const baseKey = await crypto.subtle.importKey('raw', passwordData, 'PBKDF2', false, ['deriveKey']);
    return crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: 65536,
        hash: 'SHA-256'
      },
      baseKey,
      { name: 'AES-GCM', length: 256 }, // AES-GCM 256 bit
      true,
      ['encrypt', 'decrypt']
    );
  }

  private async decryptDataToBuffer(encryptedData: string, key: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    return crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv,
        tagLength: 128
      },
      key,
      Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0))
    );
  }

  private async importKey(base64Key: ArrayBuffer): Promise<CryptoKey> {
    return crypto.subtle.importKey(
      'raw',
      base64Key,
      { name: 'AES-GCM' },
      false,
      ['encrypt', 'decrypt']
    );
  }

  private base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
}
