import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthService } from 'src/app/services/auth-service.service';
import { BackupService } from 'src/app/services/backup.service';
import {
  RemoveAccountResponse,
  UserManagementService,
  UserRemovalStatusResponse
} from 'src/app/services/user-management.service';
import { ModalController } from '@ionic/angular';
import { catchError, from, Observable, of, repeat, skipWhile, switchMap, take } from 'rxjs';
import { ActionsModalComponent } from '../../shared/actions-modal/actions-modal.component';
import { ActionsModalDto } from '../../dto/shared.dto';
import { AppModeStatus, OfflineModeService } from '../../services/offline-mode.service';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})

export class AccountPageComponent implements OnInit {
  loading: boolean;
  userName: string = '';
  email: string;
  integrationFlag: boolean = false;
  isToastOpen: boolean = false;
  fullName: string = '';
  inn: string;
  phoneNumber: string;

  constructor(
    private authService: AuthService,
    private user: UserManagementService,
    private router: Router,
    private dbService: NgxIndexedDBService,
    private activatedRoute: ActivatedRoute,
    private backupService: BackupService,
    private readonly modalCtrl: ModalController,
    readonly appModeService: OfflineModeService
  ) {
  }

  ngOnInit(): void {
    this.user.getUserData().subscribe(r => {
      if (r.data.lastName) {
        this.fullName += ` ${r.data.lastName}`;
      }
      if (r.data.firstName) {
        this.fullName += ` ${r.data.firstName} `;
      }
      if (r.data.patronymicName) {
        this.fullName += ` ${r.data.patronymicName}`;
      }

      this.inn = r.data.inn;
      this.phoneNumber = r.data.phone;
    });

    const userName = this.authService.loadUserProfile().username;
    if (userName) this.userName = userName;
    const { email } = this.authService.loadUserProfile();
    if (email) this.email = email;

    this.activatedRoute.params.subscribe(() => {
      this.dbService
        .getByKey('SmartSeedsTable', this.email)
        .subscribe(existingUser => {
          if (existingUser) {
            if (existingUser) {
              this.integrationFlag = true;
            } else {
              this.integrationFlag = false;
            }
          } else {
            this.integrationFlag = false;
          }
        });
    });
  }

  logOut() {
    this.authService.logout();
  }

  changePassword() {
    this.router.navigate(['reset-password'], {
      queryParams: { email: this.email }
    });
  }

  smartSeedsIntegration() {
    this.router.navigate(['smartseeds-integration']);
  }

  toastSetStatus(isOpen: boolean) {
    this.isToastOpen = isOpen;
  }

  async removeAccount(): Promise<void> {
    const confirmed: boolean = await this.confirmRemoveAccount();
    if (confirmed) {
      this.loading = true;
      this.removeAccountImpl().pipe(
        take(1),
        catchError(() => from(this.showAccountRemoveError()))
      ).subscribe((response: UserRemovalStatusResponse | void | null) => {
        this.loading = false;
        if (response && response.data?.status === 'Completed') {
          this.logOut();
        } else {
          this.showAccountRemoveError();
        }
      });
    }
  }

  async confirmRemoveAccount(): Promise<boolean> {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: <ActionsModalDto> {
          confirmButton: true,
          confirmButtonText: 'Удалить',
          confirmButtonClass: 'danger',
          message: 'После удаления своей учётной записи, восстановить данные обработанных заявок и профиля' +
            ' будет невозможно! Вы точно хотите это сделать?',
          title: 'Удалить учётную запись?',
          imageSrc: '../../../assets/images/trash.svg',
          errorTitle: false
        }
      }
    });
    await modal.present();
    const { role } = await modal.onWillDismiss();
    return role === 'confirm';
  }

  async showAccountRemoveError(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: <ActionsModalDto> {
          closeButton: true,
          message: 'Завершите все заявки находящиеся в работе. Если у вас нет активных заявок,' +
            ' обратитесь в техническую поддержку.',
          title: 'Невозможно удалить учётную запись',
          imageSrc: '../../../assets/images/warn-end-order.svg',
          errorTitle: true
        }
      }
    });
    return modal.present();
  }

  isOfflineStatus(status: AppModeStatus): boolean {
    return status === AppModeStatus.OFFLINE;
  }

  private removeAccountImpl(): Observable<UserRemovalStatusResponse | null> {
    return this.user.removeAccount().pipe(
      switchMap((response: RemoveAccountResponse) => {
        const businessKey: string = response?.data?.businessKey;
        if (businessKey) {
          return this.user.fetchRemovalStatus(businessKey).pipe(
            repeat({ delay: 1000 }),
            skipWhile((statusResponse: UserRemovalStatusResponse) => {
              const { data } = statusResponse;
              return data?.status === 'InProgress';
            }),
          );
        }
        return of(null);
      })
    );
  }
}
