/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { v4 as uuid } from 'uuid';
import * as JSZip from 'jszip';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  defaultIfEmpty,
  filter,
  firstValueFrom,
  from,
  groupBy,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  toArray
} from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { JSZipFileOptions } from 'jszip';
import { Order } from '../dto/order.dto';
import {
  BackupActions,
  BackupActionsInterface,
  OrderInEditStatusInterface,
  tableOrdersInEditStatus
} from '../db-description';
import { ErrorToastService } from './error-toast.service';
import { BackupApiService } from './backup-api.service';
import { RtsLoadingData, RtsVehicle } from '../dto/get-rts-result.dto';
import { EncryptedData, EncryptionService } from './encryption.service';
import { EncryptionKeyProviderService } from './encryption-key-provider.service';

export interface ConflictVersionModalData {
  localOrder: Order;
  localVersion: number;
  serverVersion: number;
}

interface ZipArchiveMetadata {
  version: number;
}

const ZIP_ARCHIVE_CURRENT_VERSION: number = 2;
const METADATA_FILE: string = 'metadata.json';

@Injectable({
  providedIn: 'root'
})
export class BackupService {
  zip = new JSZip();
  email: string;
  public currentSecretCode$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  public versionConflictModalTrigger$: Subject<ConflictVersionModalData> =
    new Subject<ConflictVersionModalData>();

  constructor(
    private dbService: NgxIndexedDBService,
    private errorToastService: ErrorToastService,
    private backupApiService: BackupApiService,
    private readonly encryptionService: EncryptionService,
    private readonly encryptionKeyProvider: EncryptionKeyProviderService
  ) { }

  /**
   * проверяем наличия ключа шифрования в хранилище и возвращаем результат
   * если ключ есть записываем его в currentSecretCode$
   * @deprecated секретные коды из IndexedDB более не актуальны.
   * @returns {any}
   */
  checkSecretCode(): Observable<string | null> {
    const email = localStorage.getItem('login');
    if (email) this.email = email;

    return this.dbService.getByKey('SecretKeyTable', this.email).pipe(
      map((existingUser: any) => {
        if (existingUser?.secretKey) {
          this.currentSecretCode$.next(existingUser.secretKey);
        } else {
          this.currentSecretCode$.next(null);
        }
        const userData = existingUser as { secretKey: string; user: string };
        return userData?.secretKey || null;
      })
    );
  }

  /**
   * Проверяем клиент на наличие несинхронизированных и устаревших бекапов
   * @returns {any}
   */
  async checkUpdates() {
    const serverBackupsData = await firstValueFrom(
      this.backupApiService.getAllBackupListWithVersion()
    );

    if (serverBackupsData) {
      from(serverBackupsData.data.backupVersions)
        .pipe(
          concatMap(backupRecord => {
            const serverVersion = backupRecord.version;

            return this.getOrderDataFromLocalStore(backupRecord.id).pipe(
              concatMap((existingRecord: any) => {
                if (existingRecord) {
                  return this.compareBackupVersion(
                    backupRecord.id,
                    serverVersion,
                    Number(existingRecord.orderBackupVersion),
                    existingRecord.Order
                  );
                }
                return this.compareBackupVersion(
                  backupRecord.id,
                  serverVersion,
                  0
                );
              })
            );
          }),
          toArray()
        )
        .subscribe({
          next: () => {
            this.executeBackupActions();
          }
        });
    }
  }

  /**
   * проверка что записать локальный или серверный бекап
   * @returns {any}
   * @param id
   * @param serverVersion
   * @param localVersion
   * @param localOrder
   */
  async compareBackupVersion(
    id: number,
    serverVersion: number,
    localVersion: number,
    localOrder?: Order
  ) {
    const idempotencyKey = uuid();

    if (localVersion === 0) {
      return firstValueFrom(
        this.writeBackupAction(
          id,
          serverVersion,
          BackupActions.SYNC_FROM_SERVER,
          idempotencyKey
        )
      );
    }

    if (serverVersion < localVersion) {
      return firstValueFrom(
        this.writeBackupAction(
          id,
          localVersion,
          BackupActions.SYNC_FROM_CLIENT,
          idempotencyKey,
          localOrder
        )
      );
    }

    if (serverVersion === localVersion) {
      return from([]);
    }

    if (serverVersion > localVersion && localOrder) {
      this.versionConflictModalTrigger$.next({
        localOrder,
        localVersion,
        serverVersion
      });
    }
    return from([]);
  }

  /**
   * возвращаем Observable запись из таблицы OrdersInEditStatus по его ID
   * @returns {any}
   * @param orderId
   */
  getOrderDataFromLocalStore(
    orderId: number
  ): Observable<OrderInEditStatusInterface | undefined> {
    return this.dbService.getByKey(tableOrdersInEditStatus, orderId);
  }

  /**
   * Записываем бекап с сервера в локальное хранилище
   * @returns {any}
   * @param orderId
   */
  writeServerBackupToLocalStore(orderId: number): Observable<any> {
    const currentLogin = localStorage.getItem('login');
    return this.backupApiService.getBackupById(orderId).pipe(
      concatMap(backupBlob => {
        const newVersion = backupBlob.headers.get('Backup-Version');

        return from(this.checkZipAndDecrypt(backupBlob.body)).pipe(
          concatMap(decryptedData => {
            if (decryptedData) {
              return this.getOrderDataFromLocalStore(orderId).pipe(
                concatMap((existingRecord: any) => {
                  if (existingRecord) {
                    if (!existingRecord.user) {
                      existingRecord.user = currentLogin;
                    }

                    existingRecord.Order = decryptedData;
                    existingRecord.orderBackupVersion = Number(newVersion);
                    return this.dbService.update(
                      tableOrdersInEditStatus,
                      existingRecord
                    );
                  }
                  const newRecord = {
                    Id: orderId,
                    Order: decryptedData,
                    orderBackupVersion: Number(newVersion),
                    user: currentLogin
                  };
                  return this.dbService.add(tableOrdersInEditStatus, newRecord);
                }),
                catchError(error =>
                    // console.error(
                    //   'Ошибка при работе с локальным хранилищем:',
                    //   error
                    // );
                     throwError(() => new Error(error))

                )
              );
            }
            return of(null);
          })
        );
      }),
      catchError(error =>
        // console.error('Ошибка при получении бэкапа с сервера:', error);
         throwError(() => new Error(error)))
    );
  }

// Функция для расшифровки данных
  decryptDataLegacy(encryptedData: string, secretKey: string): any {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

// Функция для извлечения файлов из архива и расшифровки
  async checkZipAndDecrypt(r: Blob): Promise<Order | null> {
    try {
      this.zip = new JSZip();
      const zip = await this.zip.loadAsync(r);
      const originalFile = zip.file('openData.json');
      if (!originalFile) {
        // console.error('Файл не найден в архиве');
        return null;
      }

      const openDataText = await originalFile.async('string');
      let order: Order;
      try {
        order = JSON.parse(openDataText) as Order;
      } catch (error) {
        // console.error('Ошибка при парсинге openData.json:', error);
        return null;
      }
      const encryptedFile = zip.file('encryptData');
      if (!encryptedFile) {
        // console.error('Файл "encryptData" не найден в архиве');
        return null;
      }

      const encryptedBlob = await encryptedFile.async('blob');
      const reader = new FileReader();
      const [encryptedData] = await Promise.all([new Promise<string>((resolve, reject) => {
        reader.onload = () => {
          if (reader.result !== null) {
            resolve(reader.result.toString());
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Ошибка: reader.result равен null');
          }
        };
        reader.onerror = error => {
          reject(error);
        };
        reader.readAsText(encryptedBlob);
      })]);

      if (!encryptedData) {
        // console.error('Ошибка: данные для расшифровки пусты');
        return null;
      }

      const metadata: ZipArchiveMetadata | null = await this.fetchMetadataFromZip(zip);
      if (metadata?.version) {
        order.registry = await this.decryptData(JSON.parse(encryptedData));
      } else {
        this.checkSecretCode().subscribe({
          next: secretKey => {
            if (!secretKey) {
              // console.error('Ошибка: секретный ключ отсутствует');
              return;
            }

            try {
              order.registry = this.decryptDataLegacy(encryptedData, secretKey);
            } catch (error) {
              // console.error('Ошибка при расшифровке данных:', error);
            }
          },
          error: () => {
            // console.error('Ошибка при получении ключа из хранилища:', err);
          },
        });
      }

      return order;
    } catch (error) {
      // console.error('Произошла ошибка:', error);
      return null;
    }
  }

  /**
   * Сохраняем бекап на сервер
   * @returns {any}
   * @param id
   */
  saveBackup(id: number): any {
    this.getOrderDataFromLocalStore(id).subscribe({
      next: (existingRecord: any) => {
        const idempotencyKey = uuid();
        if (existingRecord) {
          this.writeBackupAction(
            existingRecord.Order.id,
            existingRecord.orderBackupVersion,
            BackupActions.SYNC_FROM_CLIENT,
            idempotencyKey,
            existingRecord.Order
          ).subscribe(() => {
            this.executeBackupActions().then();
          });
        } else {
          this.errorToastService.errorSubject.next(500);
        }
      }
    });
  }

  parseOpenData(order: Order): Order {
    const clonedOrder = JSON.parse(JSON.stringify(order)) as Order;

    clonedOrder.registry.days.forEach(day => {
      day.vehicles = day.vehicles.map(({ id, loadingData = {} as RtsLoadingData, ...v }) => ({
        id,
        loadingData,
        ...v,
        carrierAddress: '********',
        carrierPassportDate: '********',
        carrierPassportDetails: '********',
        carrierBankDetails: '********',
        driverName: '********',
        driverPassport: '********',
        driverLicenseNumber: '********',
        driverPhone: '********',
        driverInn: '********'
      })) as RtsVehicle[];
    });

    return clonedOrder;
  }

  /**
   * Записываем запись  из локального хранилища на сервер
   * @returns {any}
   * @param order
   * @param localVersion
   * @param idempotencyKey
   * @param overwrite
   */
  writeLocalBackupToServer(
    order: Order,
    localVersion: number,
    idempotencyKey: string,
    overwrite: boolean
  ): Observable<any> {
    const openData = this.parseOpenData(order);

    return this.encryptData(order.registry).pipe(
      switchMap(encryptedData => {
        if (!encryptedData) {
          return of(null);
        }
        return from(this.packToZip(encryptedData, openData)).pipe(
          switchMap((zipBlob: Blob) =>
            this.backupApiService
              .sendBackup(
                order.id.toString(),
                zipBlob,
                localVersion,
                overwrite,
                idempotencyKey
              )
              .pipe(
                catchError(error => {
                  if (error.status === 412) {
                    const serverVersion = Number(error.error.error);
                    this.versionConflictModalTrigger$.next({
                      localOrder: order,
                      localVersion,
                      serverVersion
                    });
                  }
                  return throwError(
                    () => new Error('Precondition Failed (412) error')
                  );
                })
              )),
          catchError(error =>
            // console.error('Error packing data into zip:', error);
             throwError(() => new Error(error)))
        );
      })
    );
  }

  /**
   * архивируем зашифрованный файл
   * @returns {any}
   * @param encryptedData
   * @param unencryptedData
   */
  packToZip(encryptedData: EncryptedData, unencryptedData: Order) {
    this.zip = new JSZip();
    // Добавляем зашифрованный файл
    const zipOptions: JSZipFileOptions = {
      compression: 'DEFLATE',
      compressionOptions: { level: 5 }
    };
    this.zip.file('encryptData', JSON.stringify(encryptedData), zipOptions);
    this.zip.file('openData.json', JSON.stringify(unencryptedData), zipOptions);
    this.zip.file(METADATA_FILE, JSON.stringify(<ZipArchiveMetadata> {
      version: ZIP_ARCHIVE_CURRENT_VERSION
    }), zipOptions);
    return this.zip.generateAsync({ type: 'blob' });
  }

  /**
   * шифруем файл
   * @returns {any}
   * @param data
   */
  encryptData(data: any): Observable<EncryptedData> {
    return this.encryptionKeyProvider.encryptionKey$.pipe(
      switchMap(cryptoKey => from(
        this.encryptionService.encryptData(JSON.stringify(data), cryptoKey))
      ));
  }

  /**
   * записываем действие по синхронизации бекапа в хранилище
   * @returns {any}
   * @param id
   * @param version
   * @param action
   * @param idempotencyKey
   * @param order
   */
  writeBackupAction(
    id: number,
    version: number,
    action: BackupActions,
    idempotencyKey: string,
    order?: Order
  ) {
    const email = localStorage.getItem('login');
    if (email) this.email = email;

    const BackupActionTableData = {
      user: this.email,
      Id: id,
      Order: order,
      orderBackupVersion: version,
      backupAction: action,
      idempotencyKey
    };

    return this.dbService
      .getByKey('BackupActionsTable', [this.email, id, version])
      .pipe(
        switchMap(existingRecord => {
          const currentRecord = existingRecord as BackupActionsInterface;
          if (currentRecord) {
            return this.dbService.update('BackupActionsTable', {
              ...currentRecord,
              ...BackupActionTableData
            });
          }
          return this.dbService.add(
            'BackupActionsTable',
            BackupActionTableData
          );
        }),
        catchError(error => throwError(error))
      );
  }

  /**
   * Функция для запуска всех операций резервного копирования и обработки результатов
   * @returns {any}
   */
  async executeBackupActions() {
    this.runAllBackupActions().subscribe();
  }

  /**
   * Запускаем все отложенные действия для синхронизации бекапов
   * @returns {any}
   */
  runAllBackupActions(): Observable<any> {
    return this.getLastVersionOfBackupActions().pipe(
      concatMap(actions => {
        if (!actions || actions?.length === 0) {
          return of(null);
        }
        return from(actions).pipe(
          concatMap(action => {
            const loggedInUser = localStorage.getItem('login');

            // возможно удалит отложенные действия других пользователей, скорее всего правильнее пропускать
            if (action.user !== loggedInUser) {
              return from(this.deleteBackupActionsByVersion(
                action.Id,
                Number(action.orderBackupVersion)
              )).pipe(
                catchError(() =>
                   of(null) // Возвращаем of(null) чтобы продолжить выполнение
                )
              );
            }

            let operation$: Observable<any>;

            switch (action.backupAction) {
              case BackupActions.SYNC_FROM_CLIENT:
                operation$ = from(this.writeLocalBackupToServer(
                  action.Order,
                  Number(action.orderBackupVersion),
                  action.idempotencyKey,
                  false
                )).pipe(
                  concatMap(() =>
                    from(this.deleteBackupActionsByVersion(
                      action.Id,
                      Number(action.orderBackupVersion)
                    )))
                );
                break;
              case BackupActions.OVERWRITE_SERVER:
                operation$ = from(this.writeLocalBackupToServer(
                  action.Order,
                  Number(action.orderBackupVersion),
                  action.idempotencyKey,
                  true
                )).pipe(
                  concatMap(() =>
                    from(this.deleteBackupActionsByVersion(
                      action.Id,
                      Number(action.orderBackupVersion)
                    )))
                );
                break;
              case BackupActions.SYNC_FROM_SERVER:
                operation$ = from(this.writeServerBackupToLocalStore(action.Id)).pipe(
                  concatMap(() =>
                    from(this.deleteBackupActionsByVersion(
                      action.Id,
                      Number(action.orderBackupVersion)
                    )))
                );
                break;
              default:
                operation$ = of(null);
                break;
            }

            return operation$.pipe(
              catchError(() =>
                 of(null) // Возвращаем of(null) чтобы продолжить выполнение
              )
            );
          })
        );
      }),
      catchError(() => of(null))
    );
  }

  async deleteBackupActionsByVersion(id: number, version: number) {
    const email = localStorage.getItem('login');
    try {
       this.dbService
        .getAllByIndex('BackupActionsTable', 'Id', IDBKeyRange.only(id))
        .pipe(
          mergeMap(records => records),
          filter(
            (record: any) =>
              record.Id === id &&
              record.user === email &&
              record.orderBackupVersion <= version
          ),
          toArray()
        )
        .subscribe(filteredRecord => {
          filteredRecord.forEach(recordForDelete => {
            this.dbService
              .delete('BackupActionsTable', [
                recordForDelete.user,
                recordForDelete.Id,
                recordForDelete.orderBackupVersion
              ])
              .subscribe();
          });
        });
    } catch (error) {
    //
    }
  }

  /**
   * Получаем последнюю версию каждого действия синхронизации от
   * @returns {any}
   */
  getLastVersionOfBackupActions() {
    let originalActions = 0;
    let resultActions: any[] = [];
    const currentUser = localStorage.getItem('login');

    return this.dbService.getAll('BackupActionsTable').pipe(
      catchError(() =>
         of([])
      ),
      mergeMap(actions => actions),
      filter((record: any) => record.user === currentUser),
      groupBy((record: any) => record.Id),
      mergeMap(group$ =>
        group$.pipe(
          toArray(),
          tap(() => (originalActions += 1)),
          map(records =>
            records.sort(
              (a: any, b: any) =>
                Number(b.orderBackupVersion) - Number(a.orderBackupVersion)
            )),
          map(sortedRecords => sortedRecords[0]),
          tap(r => resultActions.push(r))
        )),
      toArray(), // Собираем все элементы в массив
      tap(() => {
        if (originalActions !== resultActions.length) {
          resultActions = []; // Очищаем результат, если длины массивов не совпадают
        }
      }),
      map(() => resultActions), // Возвращаем массив resultActions
      defaultIfEmpty([])
    );
  }

  updateLocalOrderBackupVersion(id: number, version: number) {
    this.getOrderDataFromLocalStore(id).subscribe(localOrder => {
      if (localOrder) {
        localOrder.orderBackupVersion = version;
        this.dbService.update(tableOrdersInEditStatus, localOrder).subscribe();
      }
    });
  }

  private async fetchMetadataFromZip(zip: JSZip): Promise<ZipArchiveMetadata | null> {
    const metadataFile = zip.file(METADATA_FILE);
    if (metadataFile) {
      const metadataContent: string = await metadataFile.async('string');
      return JSON.parse(metadataContent);
    }
    return null;
  }

  private async decryptData(encryptedData: EncryptedData): Promise<any> {
    const cryptoKey: CryptoKey = await this.encryptionKeyProvider.fetchEncryptionKey();
    const jsonText = await this.encryptionService.decryptData(encryptedData, cryptoKey);
    return JSON.parse(jsonText);
  }
}
