import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { MenuTabComponent } from './menu-tab/menu-tab.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order/order.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { DaysListComponent } from './days-list/days-list.component';
import { DayDetailsComponent } from './days-list/day-details/day-details.component';
import { OrdersInWorkComponent } from './orders-views/orders-in-work/orders-in-work.component';
import { ActiveOrdersComponent } from './orders-views/active-orders/active-orders.component';
import { ArchivedOrdersComponent } from './orders-views/archived-orders/archived-orders.component';
import { PhoneNumberPipe } from '../utils/pipes/phone-number.pipe';
import { SharedModule } from '../shared/shared.module';
import { ExpeditorTermsComponent } from './expeditor-terms/expeditor-terms.component';
import { EditCarComponent } from './days-list/day-details/edit-car/edit-car.component';
import { LoadCarComponent } from './days-list/day-details/load-car/load-car.component';
import { NewCarComponent } from './days-list/day-details/new-car/new-car.component';
import { EndDayComponent } from './days-list/day-details/end-day/end-day.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { NumberSeparatorPipe } from '../utils/pipes/number-separator.pipe';
import { EndOrderComponent } from './days-list/end-order/end-order.component';
import { SmartSeedsIntegrationComponent } from './smartseeds-integration/smartseeds-integration.component';
import { PluralizeDaysPipe } from '../utils/pipes/day-pipe.pipe';
import { GroupDatesPipe } from '../utils/pipes/group-date.pipe';
import { VersionConflictModalComponent } from './version-conflict-modal/version-conflict-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuTabComponent,
    OrdersComponent,
    OrderComponent,
    SearchModalComponent,
    DaysListComponent,
    DayDetailsComponent,
    OrdersInWorkComponent,
    ActiveOrdersComponent,
    ArchivedOrdersComponent,
    ExpeditorTermsComponent,
    PhoneNumberPipe,
    NumberSeparatorPipe,
    PluralizeDaysPipe,
    GroupDatesPipe,
    NewCarComponent,
    EditCarComponent,
    LoadCarComponent,
    EndDayComponent,
    AccountPageComponent,
    EndOrderComponent,
    SmartSeedsIntegrationComponent,
    VersionConflictModalComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BrowserModule,
        FormsModule,
        IonicModule.forRoot(),
        SharedModule,
        NgOptimizedImage
    ],
  exports: [HeaderComponent, VersionConflictModalComponent]
})
export class ComponentsModule { }
