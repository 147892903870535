/* eslint-disable function-paren-newline */
import { Injectable } from '@angular/core';
import { Observable, first, switchMap } from 'rxjs';
import * as _ from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Base64 } from 'js-base64';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ModalController } from '@ionic/angular';
import { RtsDay, GetRtsResultDto, RtsVehicle } from '../dto/get-rts-result.dto';
import { Order } from '../dto/order.dto';
import { BaseCacheService } from './base-cache.service';
import { Status } from '../dto/status.enum';
import { tableOrdersInEditStatus } from '../db-description';
import { BackupService } from './backup.service';
import { ConfigService } from './config.service';
import { AuthService } from './auth-service.service';
import { OfflineModeService } from './offline-mode.service';
import { SmartSeedsService } from './smart-seeds.service';

interface CheckOrderInLocalStoreResult {
  Id: number;
  Order: Order;
  user: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrdersMergeService extends BaseCacheService {
  constructor(
    dbService: NgxIndexedDBService,
    superHttp: HttpClient,
    superConfig: ConfigService,
    offlineService: OfflineModeService,
    authService: AuthService,
    modalCtrl: ModalController,
    smartSeedsService: SmartSeedsService,
    private backupService: BackupService,
  ) {
    super(
      dbService,
      superHttp,
      superConfig,
      offlineService,
      authService,
      modalCtrl,
      smartSeedsService
    );
  }

  // для списка заявок merge

  async mergeOrder(curServerOrder: Order): Promise<Order> {
    const enrichedServerOrder = await this.enrichServerOrder(curServerOrder);

    if (enrichedServerOrder) {
      return this.checkOrderInLocalStore(enrichedServerOrder);
    }

    const localSearchResult = await this.getCurrentLocalOrder(
      curServerOrder.id
    ).toPromise();

    if (localSearchResult?.Order) {
      return localSearchResult?.Order;
    }

    const updatedOrder = _.cloneDeep(curServerOrder);
    updatedOrder.registry = {
      completed: false,
      days: []
    };

    return updatedOrder;
  }

  async enrichServerOrder(order: Order): Promise<Order | null> {
    try {
      const res = await this.getRtsBySmartId(
        order.smartseedsOrderId
      ).toPromise();

      if (res && res.success) {
        order.registry = {
          completed: false,
          days: res.data.orders.map(day => ({ ...day }))
        };
      }
    } catch (error) {
      // console.error('Error enriching order:');
      return null;
    }

    return order;
  }

  getRtsBySmartId(smartOrderNumber: number): Observable<GetRtsResultDto> {
    return this.smartSeedsService.fetchUserCredentialsForSmartSeeds().pipe(
      switchMap(smartCred => {
        const params = new HttpParams()
          .set('orderNumber', smartOrderNumber.toString())
          .set('user', Base64.encode(smartCred.login))
          .set('password', Base64.encode(smartCred.password));

        const curUrl = `${this.baseRestURL}forwarding/rts`;
        return this.http.get<GetRtsResultDto>(curUrl, { params });
      })
    );
  }

  async checkOrderInLocalStore(enrichedServerOrder: Order): Promise<Order> {
    try {
      const localSearchResult = await this.getCurrentLocalOrder(
        enrichedServerOrder.id
      ).toPromise();

      if (localSearchResult?.Order.id === enrichedServerOrder.id) {
        return this.mergeWithLocalOrder(
          localSearchResult.Order,
          enrichedServerOrder
        );
      }

      if (
        !localSearchResult &&
        (enrichedServerOrder.status === Status.ACCEPTED ||
          enrichedServerOrder.status === Status.IN_WORK)
      ) {
        await this.addOrderWithRtsToLocal(enrichedServerOrder);
      }
    } catch (error) {
      // console.error('Error checking order in local store');
    }

    return enrichedServerOrder;
  }

  getCurrentLocalOrder(
    orderId: number
  ): Observable<CheckOrderInLocalStoreResult | undefined> {
    return this.dbService
      .getByKey('ordersInEditStatus', orderId)
      .pipe(first()) as Observable<CheckOrderInLocalStoreResult | undefined>;
  }

  mergeWithLocalOrder(localOrder: Order, serverOrder: Order) {
    const updatedOrder = _.cloneDeep(serverOrder);

    updatedOrder.log = _.cloneDeep(localOrder.log);

    updatedOrder.status = localOrder.status;

    updatedOrder.registry.days = [];

    serverOrder.registry.days.forEach(serverDay => {
      const localDay = this.getDayByIdFromOrder(
        localOrder,
        serverDay.orderNumber
      );

      if (localDay) {
        const mergedDay = this.mergeOneDay(localDay, serverDay);
        updatedOrder.registry.days.push(mergedDay);
      } else {
        // check it
        updatedOrder.registry.days.push(serverDay);
      }
    });
    return updatedOrder;
  }

  getDayByIdFromOrder(localOrder: Order, dayId: string) {
    const day = localOrder.registry.days.find(
      (elm: any) => elm.orderNumber === dayId
    );

    return day || undefined;
  }

  mergeOneDay(localDay: RtsDay, serverDay: RtsDay) {
    let updatedDay = _.cloneDeep(serverDay);

    if (localDay.started && !localDay.completed) {
      updatedDay.vehicles = this.updateVehicles(localDay, serverDay);
      updatedDay.started = true;
    }

    if (!localDay.started && !localDay.completed) {
      updatedDay = this.fullVehicleDataUpdate(localDay, serverDay);
    }

    if (localDay.completed) {
      updatedDay = _.cloneDeep(localDay);
    }

    return updatedDay;
  }

  updateVehicles(localDay: RtsDay, serverDay: RtsDay) {
    const newVehicles: RtsVehicle[] = [];
    const localUniqueVehicles: RtsVehicle[] = [];

    localDay.vehicles.forEach(localCar => {
      if (
        !serverDay.vehicles.find(serverCar => serverCar.id === localCar.id)
      ) {
        localUniqueVehicles.push(localCar);
        newVehicles.push(localCar);
      }
    });

    serverDay.vehicles.forEach(serverVehicle => {
        const curLocalTruckRun = this.getLocalDateVehicleRun(
          localDay,
          serverVehicle,
        );

        if (curLocalTruckRun) {
          newVehicles.push(
            this.updateVehicleToLocal(curLocalTruckRun, serverVehicle)
          );
        } else {
          const newCar = _.cloneDeep(serverVehicle);
          newVehicles.push(newCar);
        }
      return newVehicles;
    });

    return newVehicles;
  }

  getLocalDateVehicleRun(
    localDay: RtsDay,
    serverVehicle: RtsVehicle,
  ) {
    return localDay.vehicles.find(
      car => car.id === serverVehicle.id
    );
  }

  updateVehicleToLocal(localVehicle: RtsVehicle, serverVehicle: RtsVehicle) {
    let updatedVehicle = _.cloneDeep(localVehicle);

    if (!localVehicle?.loadingData) {
      updatedVehicle = serverVehicle;
    }

    return updatedVehicle;
  }

  fullVehicleDataUpdate(localDay: RtsDay, serverDay: RtsDay) {
    const localUniqueVehicles: RtsVehicle[] = [];

    localDay.vehicles.forEach(localCar => {
      if (
        !serverDay.vehicles.find(serverCar => serverCar.id === localCar.id)
      ) {
        localUniqueVehicles.push(localCar);
      }
    });

    const updatedDay = localDay;
    updatedDay.vehicles = [...serverDay.vehicles, ...localUniqueVehicles];
    return updatedDay;
  }

  deleteAbsentLocalTrucks(
    localVehicle: RtsVehicle[],
    serverVehicle: RtsVehicle[]
  ) {
    let updatedVehicle = _.cloneDeep(localVehicle);

    return (updatedVehicle = updatedVehicle.filter(car => {
      // добавить кастомные машины проверку
      if (!car?.loadingData) {
        const matchCar = serverVehicle.find(
          serverCar => car.id === serverCar.id
        );

        return !!matchCar;
      }
      return true;
    }));
  }

  addOrderWithRtsToLocal(enrichedServerOrder: Order) {
    this.dbService
      .getByKey(tableOrdersInEditStatus, enrichedServerOrder.id)
      .subscribe({
        next: (existingRecord: any) => {
          if (existingRecord) {
            // Если запись существует, обновляем ее данные
            existingRecord.Order = enrichedServerOrder;

            this.dbService
              .update(tableOrdersInEditStatus, existingRecord)
              .subscribe({
                next: () => {
                  this.backupService.saveBackup(existingRecord.Id);
                },
                error: error => {
                  console.log('Ошибка при обновлении записи', error);
                }
              });
          } else {
            // Если записи нет, вставляем новую запись
            const newRecord = {
              Id: enrichedServerOrder.id,
              Order: enrichedServerOrder,
              orderBackupVersion: 0
            };

            this.dbService.add(tableOrdersInEditStatus, newRecord).subscribe({
              next: () => {
                this.backupService.saveBackup(newRecord.Id);
              },
              error: error => {
                console.error('Ошибка при добавлении записи:', error);
              }
            });
          }
        }
      });
  }
}
