<app-header
  (filterArchiveList)="switchArchiveTab($event)"
  [headerData]="headerDataValues"
  class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<ion-content [scrollY]="false">
  <app-orders
    [ordersListData]="ordersListDataValues"
    [tabName]="'archived-requests'"
    (totalCount)="getTotalCount($event)"
    class="ion-content-scroll-host"
  ></app-orders>
</ion-content>
