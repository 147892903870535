/* eslint-disable @typescript-eslint/brace-style */
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@capacitor/clipboard';
import { ModalController, NavController } from '@ionic/angular';
import * as moment from 'moment';
import { AcceptNewOrderModalService } from 'src/app/services/accept-new-order.service';
import { OrdersService } from 'src/app/services/orders.service';
import { Subject } from 'rxjs/internal/Subject';
import { first, firstValueFrom, takeUntil } from 'rxjs';
import { RtsDay } from 'src/app/dto/get-rts-result.dto';
import { ForwarderReportGeneratorService } from 'src/app/services/docs-service/forwarder-report.service';
import { OrdersMergeService } from 'src/app/services/orders-merge.service';
import { ErrorToastService } from 'src/app/services/error-toast.service';
import { BackupService } from 'src/app/services/backup.service';
import { HeaderDto } from '../../dto/header.dto';
import { Order, OrdersResultDto } from '../../dto/order.dto';
import { ResponseDto } from '../../dto/shared.dto';
import { Status } from '../../dto/status.enum';
import { ActionsModalComponent } from '../../shared/actions-modal/actions-modal.component';
import { ARCHIVED_REQUESTS, OPEN_REQUESTS, REQUESTS_IN_WORK } from '../../utils/constants/router.constants';
import { OrderChangesService } from '../../services/order-changes.service';
import { OrderCommonComponent } from '../orders/order-common/order-common.component';
import { SmartSeedsService } from '../../services/smart-seeds.service';

@Component({
  selector: 'app-days-list',
  templateUrl: './days-list.component.html',
  styleUrls: ['./days-list.component.scss']
})
export class DaysListComponent
  extends OrderCommonComponent
  implements OnInit, OnDestroy {
  showAcceptBlock: boolean = false;
  showEndOrderBlock: boolean = false;
  orderID: number;
  headerDataValues: HeaderDto;
  daysList: RtsDay[];
  loading: boolean;
  loadingSpinner: boolean = false;
  request: Order;
  phoneHref: string;
  isToastOpen = false;
  targetUrl = window.location.pathname.split('/')[1];
  isRefresherDisabled: boolean;

  monthNames: { [key: number]: string } = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь'
  };

  private destroy$ = new Subject();
  private updateCount = 0;

  constructor(
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private router: Router,
    public cd: ChangeDetectorRef,
    private ordersService: OrdersService,
    private orderChangesService: OrderChangesService,
    private acceptNewOrderModalService: AcceptNewOrderModalService,
    private smartSeedsService: SmartSeedsService,
    private forwarderReportGeneratorService: ForwarderReportGeneratorService,
    private ordersMergeService: OrdersMergeService,
    modalCtrl: ModalController,
    private errorToastService: ErrorToastService,
    private backupService: BackupService,
  ) {
    super(modalCtrl);
  }

  ngOnInit(): void {
    this.loading = true;
    this.isRefresherDisabled = true;

    this.orderID =
      +window.location.pathname.split('/')[
      window.location.pathname.split('/').findIndex(el => el === 'days') + 1
        ];

    this.headerDataValues = {
      title: `Заявка №${this.orderID}`
    };

    this.orderChangesService
      .getOrderSubject(this.targetUrl)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(async orderUpdate => {
        this.updateCount += 1;
        this.loading = true;

        if (!orderUpdate) {
          await this.handleOrderFetch();
        } else {
          await this.handleOrderUpdate(orderUpdate);
        }

        this.loading = false;
        this.isRefresherDisabled = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  pageInitActions(r: Order) {
    this.request = r;
    this.daysList = this.request?.registry?.days;

    this.showAcceptBlock = this.request.status === Status.OPEN;

    if (r.registry) {
      this.showEndOrderBlock = this.request.status === Status.IN_WORK;
    }
    this.phoneHref = `tel:${this.request.seller.phone}`;
  }

  calculateTotalFee() {
    return this.request.requestedWeightInTons * this.request.forwarderFee;
  }

  async copyText(text: string) {
    await Clipboard.write({
      string: text
    });
    this.toastSetStatus(true);
  }

  toastSetStatus(isOpen: boolean) {
    this.isToastOpen = isOpen;
  }

  async openDayDetails(dayId: string) {
    const id = dayId.replace('|', '');
    const day = this.request.getDayById(id);
    let path;

    switch (this.request.status) {
      case Status.OPEN:
        path = OPEN_REQUESTS;
        break;
      case Status.COMPLETED:
        path = ARCHIVED_REQUESTS;
        break;
      default:
        path = REQUESTS_IN_WORK;
        break;
    }

    if (day?.vehicles.length) {
      this.navCtrl.navigateForward([`${path}/days/${this.orderID}/day/${id}`]);
    } else {
      const modal = await this.modalCtrl.create({
        component: ActionsModalComponent,
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        componentProps: {
          data: {
            errorTitle: true,
            closeButton: true,
            message:
              'Дождитесь пока логистическая компания предоставит РТС для данного дня, чтобы начать работу',
            title: 'Поиск машин',
            imageSrc: '../../../assets/images/no-rts-modal.svg',
            confirmButton: true,
            confirmButtonText: 'Создать РТС локально '
          }
        }
      });
      modal.present();
      const { role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        this.navCtrl.navigateForward(
          `${path}/days/${this.orderID}/day/${id}/add-new-car`
        );
      }
    }
  }

  async showEndOrderWarning(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          confirmButton: true,
          confirmButtonText: 'Завершить заявку',
          message:
            'Вы действительно хотите завершить заявку на экспедирование? Обратите внимание, что не все дни отгрузки были завершены.',
          title: 'Завершение заявки',
          imageSrc: '../../../assets/images/warn-end-order.svg',
          errorTitle: true
        }
      }
    });
    await modal.present();
    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.navCtrl.navigateForward([
        `requests-in-work/days/${this.orderID}/end-order`
      ]);
    }
  }

  async showOfflineWarning(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          message:
            'Нет связи. Данное действие можно делать только при наличии связи.',
          title: 'Нет связи',
          imageSrc: '../../../assets/images/offline.svg',
          errorTitle: true
        }
      }
    });
    await modal.present();
  }

  async acceptOrder() {
    const role = await this.acceptNewOrderModalService.accept();
    const userDbData = await this.smartSeedsService.fetchUserCredentialsForSmartSeedsAsync();

    if (role === 'confirm') {
      this.loadingSpinner = true;
      if (userDbData.password && userDbData.login) {
        this.ordersService
          .acceptRequest(
            this.request.id,
            userDbData.login,
            userDbData.password,
          )
          .subscribe({
            next: (r: ResponseDto) => {
              if (!r?.success) {
                this.noContentRequest();
              }
              this.ordersService
                .getOrdersList(
                  100,
                  0,
                  '',
                  [Status.IN_WORK, Status.ACCEPTED]
                )
                .pipe(first())
                .subscribe((res: OrdersResultDto) => {
                 const createdRequest = res.data.requests.find(elem =>
                   elem.id === this.request.id);
                 if (createdRequest) {
                   this.orderChangesService.setOrder(createdRequest, REQUESTS_IN_WORK);
                   setTimeout(() => {
                     this.navCtrl.navigateForward([`/requests-in-work/days/${this.request.id}`]);
                   }, 0);
                 } else {
                   this.navCtrl.navigateForward(['requests-in-work']);
                 }
                  this.loadingSpinner = false;
                });
            },
            error: (error: any) => {
              this.loadingSpinner = false;
              if (error.status === 412) {
                this.noContentRequest();
              } else {
                // Добавьте здесь обработку других ошибок, если необходимо
              }
            }
          });
      } else {
        this.errorToastService.sendError(418);
        this.navCtrl.navigateForward(['smartseeds-integration'], {
          queryParams: {
            acceptOrderStep: true,
            orderId: this.request.id
          }
        });
      }
    }
  }

  async endOrder() {
    if (!this.request.checkCompletedStatus()) {
      await this.showEndOrderWarning();
    } else {
      this.navCtrl.navigateForward([
        `requests-in-work/days/${this.orderID}/end-order`
      ]);
    }
  }

  calculateDaysDifference(startDate: number, endDate: number): number {
    const start = moment(startDate.toString());
    const end = moment(endDate.toString());
    const diff = end.diff(start, 'days');
    return diff;
  }

  downloadForwarderReport() {
    this.forwarderReportGeneratorService.getForwarderReport(this.request);
  }

  downloadForwarderUPD() {
    this.forwarderReportGeneratorService.getForwarderReport(this.request);
  }

  async updatePageData() {
    this.loading = true;
    this.isRefresherDisabled = true;
    const currentOrder = this.orderChangesService
      .getOrderSubject(this.targetUrl)
      .getValue();
    if (currentOrder) {
      const newOrder = await this.ordersMergeService.mergeOrder(currentOrder);
      this.orderChangesService.updateRequests(newOrder, this.targetUrl);
    }
    this.loading = false;
    this.isRefresherDisabled = false;
  }

  async handleRefresh(event: any) {
    this.updateCount = 1;
    this.isRefresherDisabled = true;
    await this.updatePageData();
    event.target.complete();
  }

  generateOrderImgPath() {
    const orderImagesBasePath: string = 'assets/images/product-types/';
    let orderImagesPath = orderImagesBasePath;
    const { freightName } = this.request;
    const freightNameWithoutClass = freightName.split(' ')[0];

    switch (freightNameWithoutClass) {
      case 'Пшеница':
        return orderImagesPath += 'wheat.svg';
        break;
      case 'Лён':
        return orderImagesPath += 'flax.svg';
        break;
      case 'Горох':
        return orderImagesPath += 'peas.svg';
        break;
      case 'Кукуруза':
        return orderImagesPath += 'corn.svg';
        break;
      case 'Рожь':
        return orderImagesPath += 'rye.svg';
        break;
      case 'Ячмень':
        return orderImagesPath += 'barley.svg';
        break;
      case 'Подсолнечник':
        return orderImagesPath += 'sunflower.svg';
        break;
      default:
        return orderImagesPath = '';
        break;
    }
  }

  formatDates(shipmentDates: string[]): { [key: string]: string } {
    const dateMap: { [key: string]: number[] } = {};

    // Разбор каждой даты
    shipmentDates.forEach(dateStr => {
      const date = new Date(dateStr);
      const month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
      const year = date.getFullYear();
      const day = date.getDate();

      // Формируем ключ для месяца и года
      const key = `${this.monthNames[month]} ${year}`;

      // Добавляем день в соответствующий месяц
      if (!dateMap[key]) {
        dateMap[key] = [];
      }
      dateMap[key].push(day);
    });

    // Формируем результат в требуемом формате
    const result: { [key: string]: string } = {};
    Object.keys(dateMap).forEach(key => {
      // Сортируем дни и объединяем их в строку
      const days = dateMap[key].sort((a, b) => a - b).join(', ');
      result[key] = days;
    });

    return result;
  }

  private async handleOrderFetch() {
    const orderResponse = await firstValueFrom(this.ordersService.getOrderById(this.orderID));

    if (!orderResponse) {
      await this.ordersService.noContentRequest();
      this.router.navigate(['../']);
      return;
    }

    if (orderResponse.success) {
      const { data } = orderResponse;
      this.pageInitActions(data);

      const newOrder = await this.ordersMergeService.mergeOrder(data);
      this.orderChangesService.updateRequests(newOrder, this.targetUrl);
    }
  }

  private async handleOrderUpdate(orderUpdate: Order) {
    this.pageInitActions(orderUpdate);

    if (!orderUpdate.status.includes(Status.OPEN) && this.updateCount === 1) {
      const newOrder = await this.ordersMergeService.mergeOrder(orderUpdate);
      this.orderChangesService.updateRequests(newOrder, this.targetUrl);
    }
  }
}
