import { Component, OnDestroy, OnInit } from '@angular/core';
import { Capacitor, registerPlugin } from '@capacitor/core';

import {
  FirebaseMessaging,
  GetTokenOptions
} from '@capacitor-firebase/messaging';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { LoginResponse, UserManagementService } from '../services/user-management.service';
import { AuthService } from '../services/auth-service.service';
import { environment } from '../../environments/environment';
import { VersionService } from '../services/version.service';
import { EncryptionKeyProviderService } from '../services/encryption-key-provider.service';

const GROUP_CONST = 'Forwarders';
const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const PASSWORD_SYMBOLS_PATTERN = /^\S*$/;

export interface ForwarderHmsMessagePlugin {
  getToken(opts: { appId: string }): Promise<{ token: string }>;
}
const ForwarderHmsMessage = registerPlugin<ForwarderHmsMessagePlugin>(
  'ForwarderHmsMessage'
);
export default ForwarderHmsMessage;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  public passwordIsVisible: boolean = false;
  public forgotPasswordFormIsVisible: boolean = false;
  public loginProcessError: boolean = false;
  public device_token = '';
  public platform = '';
  public isLoading: boolean = false;
  public version: string;

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(PASSWORD_SYMBOLS_PATTERN)
    ])
  });

  public forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN)
    ])
  });

  loginResponseCode?: number;

  private destroy$ = new Subject();

  constructor(
    private userManagementService: UserManagementService,
    private authService: AuthService,
    private versionService: VersionService,
    private router: Router,
    private readonly encryptionKeyProvider: EncryptionKeyProviderService
  ) {
    this.getDeviceToken();
    this.loginForm.valueChanges.subscribe(() => {
      this.loginProcessError = false;
    });
  }

  ngOnInit(): void {
    this.versionService.getVersion().subscribe(data => {
      this.version = data.version;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public login() {
    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;

    if (email && password) {
      this.isLoading = true;
      delete this.loginResponseCode;
      this.userManagementService.login(
        email,
        password,
        this.device_token,
        this.platform,
        GROUP_CONST
      ).pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isLoading = false),
        catchError(err => {
          this.handleFailedLogin(err.status);
          throw err;
        })
      ).subscribe(async (response: HttpResponse<LoginResponse>) => {
        const { body, status } = response;
        if (status === HttpStatusCode.Ok && body?.data?.dataEncryptionKey) {
          try {
            await this.encryptionKeyProvider.loadDataKeyToStorage(
              body.data.dataEncryptionKey,
              email,
              password
            );
          } catch (err) {
            this.handleFailedLogin(status);
            throw err;
          }
          this.authService.login(
            body.data.access_token,
            body.data.refresh_token,
            body.data.refresh_expires_in
          );
        } else {
          this.handleFailedLogin(status);
        }
      });
    }
  }

  public forgotPassword() {
    this.forgotPasswordFormIsVisible = true;
  }

  public async getDeviceToken(): Promise<void> {
    const options: GetTokenOptions = {
      vapidKey: environment.firebase.vapidKey
    };
    this.platform = Capacitor.getPlatform();
    // console.log('!!!getToken platform:',  this.platform);
    if (this.platform === 'web') {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register('firebase-messaging-sw.js');
    }
    try {
      const { token } = await FirebaseMessaging.getToken(options);
      this.device_token = token;
      // console.log('!!!getToken Firebase:',  this.device_token);
    } catch (e) {
      // console.log("!!!getToken Firebase e:", e);
      this.device_token = '';
    }
    if (this.device_token === '') {
      // console.log('!!!getToken environment.hmsAppId=', environment.hmsAppId);
      try {
        const { token } = await ForwarderHmsMessage.getToken({ appId: environment.hmsAppId });
        this.platform = 'huawei';
        this.device_token = token;
        // console.log('!!!getToken HMS:',  this.device_token);
      } catch (e) {
        // console.log("!!!getToken HMS e:", e);
        this.device_token = '';
      }
    }
  }

  public openRegistration() {
    this.router.navigate(['registration']);
  }

  public restorePassword() {
    this.router.navigate(['reset-password']);
  }

  private handleFailedLogin(responseCode?: number): void {
    this.loginForm.get('password')?.reset();
    this.loginProcessError = true;
    this.loginResponseCode = responseCode;
  }
}
