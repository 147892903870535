import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MenuTabChangeService } from 'src/app/services/menu-tab-change.service';
import { filter } from 'rxjs';
import { SearchModalComponent } from '../search-modal/search-modal.component';
import { SearchItem } from '../../dto/search-item.dto';
import { Status } from '../../dto/status.enum';
import { HeaderDto } from '../../dto/header.dto';
import { ActionsModalComponent } from '../../shared/actions-modal/actions-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() headerData: HeaderDto;

  @Input() closableIcon: boolean = false;

  @Output() searchTerms: EventEmitter<SearchItem[]> = new EventEmitter<
    SearchItem[]
  >();

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() filterArchiveList: EventEmitter<Status> =
    new EventEmitter<Status>();

  @Input() showBackButton?: boolean = undefined;

  showSearchBar?: boolean = false;

  showActionButtons?: boolean = false;

  selectedItems: SearchItem[] = [];

  searchbarValue: string = '';

  loadingArchiveButtonIsPrimary: boolean = true;

  unloadingArchiveButtonIsPrimary: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private tabChangeService: MenuTabChangeService
  ) {}

  ngOnInit(): void {
    if (this.headerData.tabRoute) {
      this.tabChangeService.refreshData
        .pipe(filter(curTabName => curTabName === this.headerData.tabRoute))
        .subscribe(() => {
          if (this.selectedItems.length) {
            this.searchTerms.emit(this.selectedItems);
          }
        });
    }

    this.showSearchBar =
      this.headerData.statuses?.includes(Status.IN_WORK) ||
      this.headerData.statuses?.includes(Status.OPEN);

    this.showActionButtons = this.headerData.statuses?.includes(
      Status.COMPLETED
    );
  }

  async openRTCActions() {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          addButton: true,
          exportPDFButton: true,
          exportXLSButton: true,
          title: 'Действия с РТС',
          imageSrc: '../../../assets/images/rtc.svg',
          carData: this.headerData.carsData,
          orderData: this.headerData.orderData
        }
      }
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.searchTerms.emit(data);
    }
  }

  async ionFocus() {
    await this.openModal();
  }

  public searchbarClear(): void {
    this.selectedItems = [];
    this.searchbarValue = '';
    this.searchTerms.emit([]);
  }

  public showLoadingArchive(): void {
    this.loadingArchiveButtonIsPrimary = true;
    this.unloadingArchiveButtonIsPrimary = false;
    this.filterArchiveList.emit(Status.CLOSED);
  }

  public showUnloadingArchive(): void {
    this.unloadingArchiveButtonIsPrimary = true;
    this.loadingArchiveButtonIsPrimary = false;
    this.filterArchiveList.emit(Status.COMPLETED);
  }

  public hideModalWindow() {
    this.closeModal.emit(true);
  }

  private async openModal() {
    const modal = await this.modalCtrl.create({
      component: SearchModalComponent,
      componentProps: {
        searchTerms: this.selectedItems || []
      }
    });

    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.searchbarValue = '';
      data.forEach((element: SearchItem, index: number) => {
        if (element.district.name) {
          this.searchbarValue += `${element.district.name}`;
        } else {
          this.searchbarValue += `${element.region.name}`;
        }

        if (index !== data.length - 1) {
          this.searchbarValue += ', ';
        }
      });

      this.selectedItems = [...data];
      this.searchTerms.emit(data);
    }
  }
}
