import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { HeaderDto } from '../../../dto/header.dto';
import { OrdersListDto } from '../../../dto/orders-list.dto';
import { Status } from '../../../dto/status.enum';

@Component({
  selector: 'app-archived-orders',
  templateUrl: './archived-orders.component.html',
  styleUrls: ['./archived-orders.component.scss']
})
export class ArchivedOrdersComponent implements OnInit {
  public headerDataValues: HeaderDto = {
    statuses: [Status.COMPLETED, Status.CLOSED],
    totalCount: 0,
    searchTerms: [],
    title: 'Архив',
    tabRoute: 'archived-requests'
  };

  public ordersListDataValues: OrdersListDto = {
    statuses: [],
    searchTerms: [],
  };

constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const { status } = params;
      if (status === 'COMPLETED') {
        this.switchArchiveTab(status);
      }
    });
  }

  public getTotalCount(totalCount: number) {
    this.headerDataValues = {
      ...this.headerDataValues,
      totalCount
    };
  }

  public switchArchiveTab(event: Status) {
    const newListDataValues = _.cloneDeep(this.ordersListDataValues);
    newListDataValues.statuses = [event];
    this.ordersListDataValues = newListDataValues;
  }
}
