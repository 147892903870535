import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuard } from './utils/guards/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { MenuTabComponent } from './components/menu-tab/menu-tab.component';
import { ActiveOrdersComponent } from './components/orders-views/active-orders/active-orders.component';
import { OrdersInWorkComponent } from './components/orders-views/orders-in-work/orders-in-work.component';
import { ArchivedOrdersComponent } from './components/orders-views/archived-orders/archived-orders.component';
import { DaysListComponent } from './components/days-list/days-list.component';
import {
  ACCOUNT,
  ARCHIVED_REQUESTS,
  OPEN_REQUESTS,
  REQUESTS_IN_WORK
} from './utils/constants/router.constants';
import { DayDetailsComponent } from './components/days-list/day-details/day-details.component';
import { ExpeditorTermsComponent } from './components/expeditor-terms/expeditor-terms.component';
import { NewCarComponent } from './components/days-list/day-details/new-car/new-car.component';
import { LoadCarComponent } from './components/days-list/day-details/load-car/load-car.component';
import { EndDayComponent } from './components/days-list/day-details/end-day/end-day.component';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { EndOrderComponent } from './components/days-list/end-order/end-order.component';
import { SmartSeedsIntegrationComponent } from './components/smartseeds-integration/smartseeds-integration.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppAuthGuard],
    component: MenuTabComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: OPEN_REQUESTS
      },
      {
        path: OPEN_REQUESTS,
        children: [
          {
            path: '',
            component: ActiveOrdersComponent
          },
          {
            path: 'days/:id',
            children: [
              { path: '', component: DaysListComponent },
              {
                path: 'day/:id',
                children: [{ path: '', component: DayDetailsComponent }]
              }
            ]
          }
        ]
      },
      {
        path: REQUESTS_IN_WORK,
        children: [
          {
            path: '',
            component: OrdersInWorkComponent
          },
          {
            path: 'days/:id',
            children: [
              { path: '', component: DaysListComponent },
              { path: 'end-order', component: EndOrderComponent },
              {
                path: 'day/:id',
                children: [
                  { path: '', component: DayDetailsComponent },
                  {
                    path: 'add-new-car',
                    component: NewCarComponent
                  },
                  {
                    path: 'load-car/:id',
                    component: LoadCarComponent
                  },
                  {
                    path: 'end-day',
                    component: EndDayComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: ARCHIVED_REQUESTS,
        children: [
          {
            path: '',
            component: ArchivedOrdersComponent
          },
          {
            path: 'days/:id',

            children: [
              { path: '', component: DaysListComponent },
              {
                path: 'day/:id',
                children: [
                  { path: '', component: DayDetailsComponent },
                  {
                    path: 'load-car/:id',
                    component: LoadCarComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: ACCOUNT,
        component: AccountPageComponent
      }
    ]
  },
  { path: 'expeditorTerms', component: ExpeditorTermsComponent },
  {
    path: 'auth',
    canActivate: [AppAuthGuard],
    component: AuthComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'smartseeds-integration',
    component: SmartSeedsIntegrationComponent
  },
  {
    path: 'reset-password',
    component: ForgotPasswordComponent
  },
  {
    path: '', redirectTo: OPEN_REQUESTS, pathMatch: 'full',

  },
  {
    path: '**', redirectTo: OPEN_REQUESTS,

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule { }
