<app-header
    [headerData]="{ title: 'Аккаунт' }"
    class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<ion-content>
    <div
        class="spinner"
        *ngIf="loading"
    >
        <ion-spinner name="lines"></ion-spinner>
    </div>
    <div *ngIf="!loading" class="content-wrapper">
        <span class="username">{{ userName }}</span>
        <div class="divider-line"></div>
        <div class="user-data">
            <div *ngIf="fullName" class="item">
                <div class="title">ФИО экспедитора:</div>
                <div class="value">{{ fullName }}</div>
            </div>
            <div *ngIf="email" class="item">
                <div class="title">Email:</div>
                <div class="value">{{ email }}</div>
            </div>
            <div *ngIf="inn" class="item">
                <div class="title">ИНН:</div>
                <div class="value">{{ inn }}</div>
            </div>
            <div *ngIf="phoneNumber" class="item">
                <div class="title">Телефон:</div>
                <a
                    class="phone"
                    href="tel:{{ phoneNumber }}"
                >
                    {{ phoneNumber | phoneNumber }}
                </a>
            </div>
        </div>



        <div class="divider-line"></div>
        <ion-button
            style="margin: 24px 0"
            (click)="logOut()"
            class="square light large"
        >
            <ion-icon
                class="button-icon"
                src="assets/icon/account-exit.svg"
                alt
            ></ion-icon>
            <span class="button-text">Выйти из аккаунта</span>
        </ion-button>
      <ng-container *ngIf="appModeService.appModeStatus$ | async as status">
        <ion-button
            style="margin-bottom: 24px"
            [disabled]="isOfflineStatus(status)"
            (click)="changePassword()"
            class="square light large"
        >
            <ion-icon
                class="button-icon"
                src="assets/icon/change-pass-key.svg"
                alt
            ></ion-icon>
            <span class="button-text">Изменить пароль</span>
        </ion-button>

      <ion-button class="square light large" style="margin-bottom: 24px"
                  [disabled]="isOfflineStatus(status)"
                  (click)="removeAccount()">
        <ion-icon class="button-icon"
                  src="assets/icon/close-smaller.svg">
        </ion-icon>
        <span class="button-text">Удалить учётную запись</span>
      </ion-button>
      </ng-container>

        <div
            class="divider-line"
            style="margin-bottom: 24px;"
        ></div>



        <span
            class="need-integration-note"
            *ngIf="!integrationFlag"
        >
            Пройдите процедуру интеграции!
        </span>





        <span class="integration-note">
            Интеграция со SMART SEEDS необходима для полноценной работы приложения
        </span>




        <div
            class="integration-done-block"
            *ngIf="integrationFlag"
        >
            <span>Интеграция пройдена</span>
            <img
                src="assets/icon/checked-icon-round.svg"
                alt
            >
        </div>






        <ion-button
            (click)="smartSeedsIntegration()"
            class="square light large custom-sized"
        >
            <ion-icon
                class="button-icon"
                src="assets/icon/smartseeds-integration.svg"
                alt
            ></ion-icon>
            <span class="button-text">Интеграция со SMART SEEDS</span>
        </ion-button>
    </div>
</ion-content>






<ion-toast
    [isOpen]="isToastOpen"
    message="Скопировано"
    position="middle"
    [duration]="2000"
    class="copy-toast"
    (didDismiss)="toastSetStatus(false)"
    [animated]="true"
></ion-toast>
