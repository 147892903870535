<div class="logo-section">
  <div class="logo">
    <img src="../../assets/images/logo.png" alt="logo" />
    <img
      src="../../assets/images/logo-title.png"
      alt="logo-title"
      class="logo-second"
    />
  </div>

  <div class="row white-text">приложение экспедитора</div>
</div>

<div class="form form-container">
  <form [formGroup]="loginForm" (keyup.enter)="login()">
    <br />
    <app-base-input
      style="width: 100%"
      [placeholder]="'Пользователь'"
      [roundCorner]="true"
      formControlName="email"
    ></app-base-input>
    <br />
    <app-base-input
      [placeholder]="'Пароль'"
      [type]="'password'"
      style="width: 100%"
      [roundCorner]="true"
      formControlName="password"
    ></app-base-input>

    <div class="error-section">
      <span class="error" *ngIf="loginProcessError">
        <ng-container [ngSwitch]="loginResponseCode">
          <ng-template ngSwitchCase="204">
            Пользователь не зарегистрирован в системе.
          </ng-template>
          <ng-template ngSwitchCase="401">
            Неверные логин или пароль.
          </ng-template>
          <ng-template ngSwitchCase="406">
            Пожалуйста, подтвердите регистрацию по ссылки из отправленного письма.
          </ng-template>
          <ng-template ngSwitchDefault>
            В процессе авторизации возникла ошибка. Проверьте правильность введенных
            данных.
          </ng-template>
        </ng-container>
      </span>
    </div>
    <br />
    <ion-button
      class="large login-text"
      mode="md"
      (click)="login()"
      [disabled]="loginForm.invalid || isLoading"
      >Войти</ion-button
    >

    <div
      (click)="openRegistration()"
      class="row green-text registration-margin"
    >
      <a>Регистрация</a>
    </div>
  </form>
</div>

<div (click)="restorePassword()" class="row green-text">
  <a>Восстановить пароль</a>
</div>

<div style="position: absolute; bottom: 0; right: 10px">{{ version }}</div>
