import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { first } from 'rxjs';
import { HeaderDto } from 'src/app/dto/header.dto';
import { Order } from 'src/app/dto/order.dto';
import { SyncAction } from 'src/app/dto/sync-actions.enum';
import {
  AppModeStatus,
  OfflineModeService
} from 'src/app/services/offline-mode.service';
import { OrderChangesService } from 'src/app/services/order-changes.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-end-order',
  templateUrl: './end-order.component.html',
  styleUrls: ['./end-order.component.scss']
})
export class EndOrderComponent implements OnInit {
  targetUrl = window.location.pathname.split('/')[1];
  orderId: string = '';
  request: Order;
  isOfflineMode: boolean;
  dayEndInOffline: boolean = false;
  loading: boolean = false;
  endDayConfirmed: boolean = false;
  expeditorDocGenerating: boolean = false;
  totalWeight: number = 0;
  headerDataValues: HeaderDto = {
    title: ''
  };

  constructor(
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private orderChangesService: OrderChangesService,
    private ordersService: OrdersService,
    private offlineModeService: OfflineModeService
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(parameter => {
      this.orderId = parameter['id'];
    });

    this.offlineModeService.appModeStatus$.subscribe(
      (mode: AppModeStatus) =>
        (this.isOfflineMode = mode === AppModeStatus.OFFLINE)
    );

    this.orderChangesService
      .getOrderSubject(this.targetUrl)
      .subscribe(currentRequest => {
        if (currentRequest) {
          this.request = currentRequest;
          this.headerDataValues.title = `Заявка № ${this.request.id}`;
          this.totalWeight = this.request.getTotalNetWeight();
        }
      });
  }

  endOrder(event: boolean): void {
    if (event) {
      if (!this.isOfflineMode) {
        this.expeditorDocGenerating = true;
        this.endDayConfirmed = true;

        this.request.completeOrder();
        this.ordersService.finishForwarding(this.request.id).subscribe();

        this.orderChangesService.updateRequests(this.request, this.targetUrl);

        setTimeout(() => {
          this.expeditorDocGenerating = false;
          this.navCtrl.navigateForward(['archived-requests'], {
            queryParams: { status: 'COMPLETED' }
          });
        }, 10000);
      } else {
        this.expeditorDocGenerating = false;
        this.dayEndInOffline = true;
        this.ordersService.addSyncAction(this.request.id, SyncAction.endDay);
      }
    } else {
      this.dayEndInOffline = false;
      this.expeditorDocGenerating = false;
      this.endDayConfirmed = false;

      this.navCtrl.navigateForward(['days']);
    }
  }

  navBack() {
    this.navCtrl.pop();
  }
}
