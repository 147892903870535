import { Injectable } from '@angular/core';
import { defer, from, Observable } from 'rxjs';
import { EncryptionService } from './encryption.service';
import { SecureStorageService } from './secure-storage.service';
import { UserDataKey } from './user-management.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptionKeyProviderService {
  public readonly encryptionKey$: Observable<CryptoKey> = defer(
    () => from(this.fetchEncryptionKey())
  );

  constructor(
    private readonly encryptionService: EncryptionService,
    private readonly secureStorageService: SecureStorageService
  ) {
  }

  public async fetchEncryptionKey(): Promise<CryptoKey> {
    const user = localStorage.getItem('login');
    const key = await this.secureStorageService.fetchData(`${user}/data-key`);
    if (!key) {
      throw new Error('Ключ шифрования не доступен!');
    }
    return this.encryptionService.loadKeyFromString(key);
  }

  public async loadDataKeyToStorage(
    dataEncryptionKey: UserDataKey,
    user: string,
    userPassword: string
  ): Promise<void> {
    const key = await this.encryptionService.decryptDataKey(dataEncryptionKey, userPassword);
    return this.secureStorageService.storeData(`${user}/data-key`, key);
  }
}
