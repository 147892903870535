/* eslint-disable function-paren-newline */
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SyncAction } from '../dto/sync-actions.enum';
import { OrderByIdResultDto, OrdersResultDto } from '../dto/order.dto';
import { Status } from '../dto/status.enum';
import { ActionsModalComponent } from '../shared/actions-modal/actions-modal.component';
import { BaseCacheService } from './base-cache.service';
import { ResponseDto } from '../dto/shared.dto';
import { OrdersMergeService } from './orders-merge.service';
import { AuthService } from './auth-service.service';
import { ConfigService } from './config.service';
import { OfflineModeService } from './offline-mode.service';
import { SmartSeedsService } from './smart-seeds.service';

export interface SyncActionsInterface {
  user: string;
  syncActions: { syncActionsType: SyncAction; actionTargetValue: any }[];
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService extends BaseCacheService {
  constructor(
    private ordersMergeService: OrdersMergeService,
    dbService: NgxIndexedDBService,
    superHttp: HttpClient,
    superConfig: ConfigService,
    offlineService: OfflineModeService,
    authService: AuthService,
    modalCtrl: ModalController,
    smartSeedsService: SmartSeedsService
  ) {
    super(
      dbService,
      superHttp,
      superConfig,
      offlineService,
      authService,
      modalCtrl,
      smartSeedsService
    );
  }

  public getOrdersList(
    limit: number,
    offset: number,
    loadingIds: string,
    status?: Status[]
  ): Observable<OrdersResultDto> {
    const params = new HttpParams()
      .set('status', status?.join(',') || '')
      .set('limit', limit.toString())
      .set('offset', offset.toString())
      .set('loadingIds', loadingIds);

    return this.http
      .get<OrdersResultDto>(`${this.baseRestURL}forwarding/request/list`, {
        params
      })
      .pipe(
        tap(response => {
          if (response.success) {
            this.saveOrdersList(response, params, status);
            this.runAllActions();
          }
        }),
        catchError(error => this.handleError(error, params, status))
      );
  }

  public getOrderById(forwardingRequestId: number): Observable<OrderByIdResultDto | null> {
    const curUrl = `${this.baseRestURL}forwarding/request/${forwardingRequestId}`;
    return this.http.get<OrderByIdResultDto>(curUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<OrderByIdResultDto>) => {
        if (response.status === 204) {
          return null;
        }
        return response.body;
      })
    );
  }

  public acceptRequest(
    requestId: number,
    smartseedsUsername: string,
    smartseedsPassword: string,
  ): Observable<ResponseDto> {
    const data = {
      requestId,
      smartseedsUsername,
      smartseedsPassword,
    };
    return this.http
      .post<ResponseDto>(`${this.baseRestURL}forwarding/request/accept`, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            // Ошибка отсутствия соединения
            // показываем диалог, что нет связи и просим сделать это позже
            this.showOfflineWarning().then(() => { });
          } else {
            // Остальные ошибки
            // показывает сообщение, что что-то пошло не так и предлагаем сделать это позже
          }
          return throwError(() => error);
        })
      );
  }

  public async noContentRequest() {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          message:
            'Данная заявка уже принята в работу . Попробуйте принять в работу другую заявку.',
          title: 'Это заявка уже была принята другим экспедитором',
          imageSrc: '../../../assets/images/no-content.svg',
          errorTitle: true
        }
      }
    });
    await modal.present();
  }

  public async showOfflineWarning(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          message:
            'Нет связи. Данное действие можно делать только при наличии связи. Попробуйте немного позже.',
          title: 'Нет связи',
          imageSrc: '../../../assets/images/offline.svg',
          errorTitle: true
        }
      }
    });
    await modal.present();
  }

  public declineRequest(
    requestId: number,
    smartseedsUsername: string,
    smartseedsPassword: string,
  ): Observable<ResponseDto> {
    const data = {
      requestId,
      smartseedsUsername,
      smartseedsPassword,
    };
    return this.http
      .post<ResponseDto>(`${this.baseRestURL}forwarding/request/decline`, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            // Ошибка отсутствия соединения
            // показываем диалог, что нет связи и просим сделать это позже
            this.showOfflineWarning().then(() => { });
          } else {
            // Остальные ошибки
            // показывает сообщение, что что-то пошло не так и предлагаем сделать это позже
          }
          return throwError(() => error);
        })
      );
  }

  public filterByRegion(filter: string): Observable<any> {
    const params = new HttpParams().set('filter', filter);
    return this.http.get<any>(`${this.baseRestURL}forwarding/regions`, {
      params
    });
  }

  public startForwarding(requestId: number): Observable<any> {
    const curUrl = `${this.baseRestURL}forwarding/request/${requestId}/start`;
    return this.http.post(curUrl, {}).pipe(
      catchError(() => {
        this.addSyncAction(requestId, SyncAction.startDay);
        return throwError(
          () => new Error('Something bad happened; please try again later.')
        );
      })
    );
  }

  public finishForwarding(requestId: number): Observable<any> {
    const curUrl = `${this.baseRestURL}forwarding/request/${requestId}/finish`;
    return this.http.post(curUrl, {}).pipe(
      catchError(() => {
        this.addSyncAction(requestId, SyncAction.endDay);
        return throwError(
          () => new Error('Something bad happened; please try again later.')
        );
      })
    );
  }

  public addSyncAction(value: any, action: SyncAction) {
    const { email } = this.authService.loadUserProfile();
    const newRecord = {
      user: this.getCurEmail(),
      syncActions: [{ syncActionsType: action, actionTargetValue: value }]
    };
    const newActions = { syncActionsType: action, actionTargetValue: value };

    if (email) {
      this.dbService.getByKey('SyncActionsTable', email).subscribe(r => {
        if (r) {
          const tableRecords = r as SyncActionsInterface;
          if (tableRecords && tableRecords?.syncActions) {
            tableRecords?.syncActions.push(newActions);
            this.dbService.update('SyncActionsTable', tableRecords).subscribe();
          }
        } else {
          this.dbService.add('SyncActionsTable', newRecord).subscribe(() => { });
        }
      });
    }
  }

  public runAllActions() {
    const { email } = this.authService.loadUserProfile();
    if (email) {
      this.dbService
        .getByKey('SyncActionsTable', email)
        .subscribe(r => {
          const tableRecords = r as SyncActionsInterface;

          if (tableRecords && tableRecords?.syncActions?.length) {
            tableRecords.syncActions.forEach((action, index) => {
              switch (action.syncActionsType) {
                case SyncAction.startDay:
                  this.startForwarding(action.actionTargetValue).subscribe(
                    startResult => {
                      if (startResult.success) {
                        tableRecords.syncActions.splice(index, 1);
                        this.dbService
                          .update('SyncActionsTable', tableRecords)
                          .subscribe();
                      }
                    }
                  );
                  break;

                case SyncAction.endDay:
                  this.finishForwarding(action.actionTargetValue).subscribe(
                    finishResult => {
                      if (finishResult.success) {
                        tableRecords.syncActions.splice(index, 1);
                        this.dbService
                          .update('SyncActionsTable', tableRecords)
                          .subscribe();
                      }
                    }
                  );
                  break;

                default:
                  break;
              }
            });
          }
        });
    }
  }

  private addOneResult(
    curDbName: string,
    response: OrdersResultDto,
    params: HttpParams
  ) {
    const pageStartId: number = Number(params.get('pageStartId')) || 0;
    this.dbService
      .add(curDbName, {
        pageId: pageStartId,
        user: this.getCurEmail(),
        filter: '',
        response
      })
      .subscribe(() => { });
  }

  private saveOrdersList(
    response: OrdersResultDto,
    params: HttpParams,
    status?: Status[]
  ): void {
    const pageStartId = Number(params.get('pageStartId')) || 0;
    const curDbName: string = this.getCurOrdersDbName(status);

    if (pageStartId === 0) {
      this.dbService.clear(curDbName).subscribe(() => {
        this.addOneResult(curDbName, response, params);
      });
    } else {
      this.addOneResult(curDbName, response, params);
    }
  }

  private handleError(
    error: HttpErrorResponse,
    params: HttpParams,
    status?: Status[]
  ): Observable<OrdersResultDto> {
    if (error.status === 0) {
      const curDbName: string = this.getCurOrdersDbName(status);

      const pageStartId = Number(params.get('pageStartId')) || 0;

      // необходим для случая когда в БД не записано ни одного результата
      const emptyResult: OrdersResultDto = {
        success: true,
        data: {
          requests: [],
          total: 0,
          currentPageSize: 0
        }
      };

      return this.dbService
        .getByKey(curDbName, [pageStartId, '', this.getCurEmail()])
        .pipe(
          map((res: any) => {
            if (res?.response?.data) {
              return res.response;
            }
            return emptyResult;
          })
        );
    }
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}
